
export function getVals_(data_) {
    console.log("getVals():", data_)
    //let jsonObject = JSON.parse(data_)

    //console.log(values);
    let str = ""
    for (let item_ of data_) {
        const values = Object.values(item_);
        str += values + "\n";
    }
    console.log("getVals()out:", str);
    return str;
}

// data = [{ "asd": "qwer", "id": "20240829_092622_063", "dataF": "3245" }, { "asd": "qwer", "id": "20240829_092622_063", "dataF": "3245" }]
// console.log(getVals(data))


export function fnVar2string(data_) {

    if (typeof data_ === "string")
        return data_

    if (data_ instanceof Array) {
        let str = ""
        data_.map((item, idx) => (
            str += fnVar2string(item)
        ))
        return str;
    }

    try {
        //JSON.parse(data_);
        return JSON.stringify(data_);
    } catch (e) {
        //not JSON;
    }

    return data_.toString();
}

export function fnToString(data_) {
    let str = ""

    if (data_ === undefined || data_ === null)
        return str;

    if (typeof data_ === "string")
        return data_

    if (data_ instanceof Array) {
        data_.map((item, idx) => (
            str += fnToString(item) + "\n"
        ))
    } else {
        try {
            //JSON.parse(data_);
            if (JSON.stringify(data_) !== '""')
                str = `${data_.timeFrom}-${data_.timeTo} ${data_.places}`

        } catch (e) {
            //not JSON;
        }
    }

    return str;
}