import React, { forwardRef } from 'react';
import "./dialog.css"

const Dialog = forwardRef(({ children, handleClose }, ref) => {

    // function handleCloseL(){
    //     onClick={() => ref.current.close()}
    // }
    return (

        <dialog className="card" ref={ref}>
            <div className='dialogBody'>
                {children}

            </div>
            <br />
            <center>
                <button onClick={() => ref.current.close()}>Сховати</button>
            </center>
        </dialog>
    )

});

export default Dialog;