import { useState, useContext } from 'react';
import './App.css';
import Finder from './app/finder/Finder.jsx';
import Trains from './app/train/Trains.jsx';
import { UserDataContextProvider } from './app/store/UserDataContext';
import imgTrain from './app/train.png';

function App() {

  const [dataTrains, setDataTrains] = useState([])
  function callBackFound(data) {
    setDataTrains(data)
  }

  return (
    <div className="App">
      booKing-0.7.5
      <header className="App-header">
        <img src={imgTrain} alt='imgTrain' width={150} />
        <UserDataContextProvider>
          <Finder callBackFound={callBackFound} />
          <Trains data={dataTrains} />
        </UserDataContextProvider>
      </header>
    </div>
  );
}

export default App;
