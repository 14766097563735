import { fnTranslate } from "../functions/fnTranslate.js"
import UserDataContext from "../store/UserDataContext";
import { useContext } from "react"


export default function ResponseMsg({ msg }) {

    const dataCtx = useContext(UserDataContext);
    function callbackHandleRepeat(onOff) {
        console.log(onOff)
        if (onOff)
            dataCtx.put({ "repeat": "on" });
        else
            dataCtx.del({ "repeat": "on" });
    }

    let checkRepeat = <span></span>
    if (msg.toUpperCase().includes("TIME_OUT")) {
        checkRepeat = <>
            <br />
            <input type="checkbox" id="checkRepeat"
                onChange={(e) => callbackHandleRepeat(e.target.checked)}
                checked={dataCtx.isMonitor()} />
            <label for="checkRepeat">повторювати доки не знайдеться</label>
        </>
    }
    return (
        <div >
            {/* {msg} */}
            {fnTranslate(msg)}
            {checkRepeat}
        </div>
    )
}