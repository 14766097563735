import Train from "./Train.jsx"
import "./train.css"
import UserDataContext from "../store/UserDataContext";
import { useEffect, useContext, useRef, useState } from "react";
import { fnToString } from "../notify/functions.js";
import Dialog from "../dialog/Dialog.jsx"
import NotificationComponent from '../notify/NotificationComponent.jsx';
import imgEye from "./eye.svg"
import { formatTime } from '../functions/fnDate.js';
import WakeLockComponent from '../notify/WakeLockComponent'


export default function Trains({ data }) {

    const dialogRef = useRef(null)
    function handleClose() {
        dialogRef.current.close();
    }

    function handleOpen() {
        if (dialogRef.current)
            dialogRef.current.showModal();
    }

    const dataContext = useContext(UserDataContext);
    const [dataMonitorDetect, setDataMonitorDetect] = useState([])
    const [msgNotify, setMsgNotify] = useState("")
    const [timeUpdated, setTimeUpdated] = useState(null)

    useEffect(() => {
        let res = dataContext.monitorData(data)
        if (res) {
            setDataMonitorDetect(old => [...old, formatTime(new Date()), ...res])
            handleOpen()
            //setMsgNotify(fnVar2string(res))
            setMsgNotify(res)
        }
        setTimeUpdated(formatTime(new Date()))
    }, [data])

    const [isWakeLockActive, setIsWakeLockActive] = useState(false);
    useEffect(() => { setIsWakeLockActive(dataContext.isMonitor) }, [dataContext.data])

    if (data === undefined || data.length === 0)
        return null;


    return (
        <>
            <div className='card'>

                <table className="train" border={0}><tbody>
                    <tr>
                        <th>Відпр.</th>
                        <th>Прибут.</th>
                        <th>Вільних місць</th>
                        <th><img src={imgEye} alt="moni" onClick={handleOpen} /></th>
                    </tr>

                    {data.map((tr, idx) => (
                        <Train data={tr} key={idx} />
                    ))}
                </tbody>
                </table>



            </div>

            <Dialog ref={dialogRef} handleClose={handleClose}>

                <b>Історія</b>
                <div style={{ textAlign: "left" }}>
                    {dataMonitorDetect.map((item, idx) => (
                        <div key={idx}>
                            {JSON.stringify(item).length === 7 && <hr />}
                            {fnToString(item)}

                        </div>
                    ))}

                </div>

            </Dialog>

            <NotificationComponent msg={msgNotify} />

            <WakeLockComponent isWakeLockActive={isWakeLockActive} />

        </>
    )
}