import React, { useEffect } from 'react';
import { fnToString } from "./functions.js";


const NotificationComponent = ({ msg }) => {
    useEffect(() => {
        // Register service worker and request permission
        const registerServiceWorker = async () => {
            try {
                const registration = await navigator.serviceWorker.register('/sw.js');
                console.log('Service Worker registered with scope:', registration.scope);

                const permission = await Notification.requestPermission();
                if (permission === 'granted') {
                    console.log('Notification permission granted.');

                    // Simulate receiving push notifications at regular intervals
                    //startNotificationInterval(registration);
                } else {
                    console.log('Notification permission denied.');
                }
            } catch (error) {
                console.error('Service Worker registration failed:', error);
            }
        };

        // // Function to start an interval to send notifications
        // const startNotificationInterval = (registration) => {
        //     setInterval(() => {
        //         const title = 'Notification:' + msg;
        //         const options = {
        //             body: title,
        //             icon: 'icon.png', // Optional: path to your notification icon
        //         };
        //         if (msg)
        //             registration.showNotification(title, options);
        //     }, 5000); // Repeats every 5 seconds
        // };

        registerServiceWorker();
    }, []);

    useEffect(() => {
        //if (!msg_) return; // Do nothing if there's no message

        const showNotification = () => {
            // Check if service worker is available
            // if ('serviceWorker' in navigator)
            {
                navigator.serviceWorker.getRegistration().then(registration => {
                    if (registration && msg) {
                        const title = 'booKing';
                        const options = {
                            body: fnToString(msg),
                            icon: 'icon.png', // Optional: path to your notification icon
                        };
                        registration.showNotification(title, options);
                    } else {
                        console.error('No service worker registration found.');
                    }
                }).catch(error => {
                    console.error('Error getting service worker registration:', error);
                });
            }
        };

        showNotification();
    }, [msg]);


    return (

        <p>
            Якщо дозволите програмі нотіфікаціі,
            то зможете отримати повідомлення про квітки.
        </p>

    );
};

export default NotificationComponent;
