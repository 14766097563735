function isASCII(str) {
    // Regular expression to match non-ASCII characters
    const nonASCIIRegex = /[^\x00-\x7F]/;
    return !nonASCIIRegex.test(str);
}

export function en2Ukr(str_) {
    let map = {
        'A': 'Ф',
        'B': 'И',
        'C': 'с',
        'D': 'В',
        'E': 'У',
        'F': 'А',
        'G': 'П',
        'H': 'Р',
        'I': 'Ш',
        'J': 'О',
        'K': 'Л',
        'L': 'Д',
        'M': 'Ь',
        'N': 'Т',
        'O': 'Щ',
        'P': 'З',
        'Q': 'Й',
        'R': 'К',
        'S': 'І',
        'T': 'Е',
        'U': 'Г',
        'V': 'М',
        'W': 'Ц',
        'X': 'Ч',
        'Y': 'Н',
        'Z': 'Я',
        ';': 'Ж',
        ',': 'Б',
        '.': 'Ю',
        '[': 'Х'

    }
    let result = ""
    for (let char_ of str_) {
        let charUkrAlias = map[char_.toUpperCase()] || char_;
        result += charUkrAlias
    }
    return result;
}
// console.log(en2Ukr("Пошук D"))
// console.log(en2Ukr("asdf"))