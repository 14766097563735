import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parse } from 'date-fns';
import { uk } from 'date-fns/locale';
import "./date.css"

const CustomDatePicker = ({ defaultDate, onChangeCallback }) => {
    const [startDate, setStartDate] = useState(defaultDate || null);

    // Handle date change
    const handleDateChange = (date) => {
        setStartDate(date);
        onChangeCallback("date", format(date, 'dd.MM.yyyy'));
    };

    // Format the date as dd.MM.yyyy
    const formatDate = (date) => {
        return date ? format(date, 'dd.MM.yyyy') : '';
    };

    return (
        <div>
            <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                dateFormat="dd.MM.yyyy"
                placeholderText="Дата"
                customInput={<input />}  // Use a custom input component if needed
                locale={uk}
                name='date'
                className='classInputDate'
            />
            {/* <p>Selected Date: {startDate ? formatDate(startDate) : ''}</p> */}
        </div>
    );
};

export default CustomDatePicker;
