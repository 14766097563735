const datafnTranslate = {
    "ERROR_TIME_OUT": "рейсiв не знайшлося",
    "ERROR_DATE_VALUE": "помилка поля Дата",
    "REQUEST_SENT": "Запит надіслано, чекаємо на відповідь...",
    "REQUEST_SEND": "Надсилаємо запит...",
    "DATA_WAITING": "Чекаємо...",
    "DATA_FETCH": "Отримуємо дані",
    "TIME_OUT": "Не діждалися відповіді"
}

export function fnTranslate(key_) {
    console.log(key_)
    let val = datafnTranslate[key_];
    if (val === undefined) {
        if (String(key_).toUpperCase().includes("TIMEOUT")) {
            val = datafnTranslate["TIME_OUT"]
        }
    }

    if (val !== undefined)
        return val;

    if (key_.toUpperCase().includes("ERROR"))
        return "Якась помилка, будемо з'ясовувати";

    return key_;
}


//console.log(fnTranslate("ERROR_TIME_OUT"));