import { useState, useContext } from "react"
import UserDataContext from "../store/UserDataContext";


export default function Train({ data }) {

    const dataContext = useContext(UserDataContext);

    const [isChecked, setIsChecked] = useState(false);

    // Event handler to toggle checkbox state
    const handleCheckboxChange = (event, data) => {
        //console.log("handleCheckboxChange()", event.target, data)

        setIsChecked(event.target.checked);
        dataContext.set(JSON.stringify(data), event.target.checked)
    };

    // useEffect will run whenever isChecked changes
    // useEffect(() => {
    //     //handleMonitor(isChecked);
    //     console.log(isChecked);
    // }, [isChecked]);


    return (
        <tr><td>{data.timeFrom?.split(" ")[0]}<br />
            {data.timeFrom?.split(" ")[1]}</td>
            <td>{data.timeTo?.split(" ")[0]}<br />
                {data.timeTo?.split(" ")[1]}</td><td>{data.places}</td>
            <td>

                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(event) => handleCheckboxChange(event, data)}
                />
            </td>
        </tr>
    )
}