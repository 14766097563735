import { useEffect, useState, useContext } from 'react';
import Loading from "../loading/Loading.jsx"
import { formatDate, formatDateCalend, tomorrow, formatTime } from '../functions/fnDate.js';
import config from "../../config/config.json"
import imgArrows from "./arrow-left-right.svg"
import imgClock from "./stopwatch.svg"
import UserDataContext from '../store/UserDataContext';
import AutoCompleteInput from "./input/autocomplete/AutoCompleteInput.jsx"
import CustomDatePicker from './input/date/CustomDatePicker.jsx';
import ResponseMsg from './ResponseMsg.jsx';
import { Progress } from '../Progress.jsx';

export default function Finder({ callBackFound }) {

    const [formData, setFormData] = useState({
        from: "Львів",
        to: "Київ",
        date: formatDate(tomorrow()),
    })
    // console.log(formData)

    const [responseMessage, setResponseMessage] = useState('Якщо "хтось вже придбав усі квітки" на ваш рейс, то booKing може повідомити вас, коли квітки з\'являться');

    const handleInputChange = (name, value) => {
        //const { name, value } = event.target;
        setFormData((old) => ({
            ...old,
            [name]: value,
        }));
    };

    const handleSwap = () => {
        setFormData((prevData) => ({
            ...prevData,
            from: prevData.to,
            to: prevData.from,
        }));
    };

    async function formSubmit(e) {
        e.preventDefault()
        callBackFound([])
        dataCtx.clr()
        localStorage.setItem('formData', JSON.stringify(formData));
        dataPost();
    }

    useEffect(() => {
        let tmp = localStorage.getItem("formData");
        try {
            let obj = JSON.parse(tmp)
            console.log(obj);
            handleInputChange("from", obj.from);
            handleInputChange("to", obj.to);
        } catch (error) {
            localStorage.setItem('formData', "");
        }
    }, [])

    const dataGet = async () => {
        try {
            setResponseMessage(("DATA_FETCH"));
            let url = config.api + "?from=" + formData.from + "&to=" + formData.to + "&date=" + formData.date;
            const response = await fetch(url);
            if (!response.ok) {
                setResponseMessage(`Error: ${response.status}`);  // Handle non-200 responses
            }
            const result = await response.json();  // Parse JSON response
            dataValidateAndProcess(result)
        } catch (error) {
            setResponseMessage(error.message);
        } finally {
            // Set loading to false when the request is complete
        }
    };

    function dataValidateAndProcess(dataJson) {
        console.log("dataValidateAndProcess():", dataJson)
        setProgress(old => old + 1)

        if (dataJson.dataF === undefined || dataJson.dataF === null || dataJson.dataF.trim() === "") {
            setResponseMessage(("DATA_WAITING"));
            return;
        }

        try {
            if (!dataJson.dataF.startsWith("ERROR")) {
                console.log(dataJson.dataF);
                callBackFound(JSON.parse(dataJson.dataF));
                setResponseMessage("Станом на " + formatTime(new Date()))
                setProgress(0)
            }
            else {
                setResponseMessage((dataJson.dataF))
            }

        } catch (error) {
            setResponseMessage(error.message)
        }
        setLoading(false)
    }

    const [loading, setLoading] = useState(false)
    const [count, setCount] = useState(0);

    useEffect(() => {
        let intervalId;

        if (loading) {
            intervalId = setInterval(() => {
                //console.log("interval, monitor:" + monitor)
                //if (!dataCtx.monitor())
                setCount((oldCount) => {
                    if (oldCount > 0) {
                        console.log(`Count: ${oldCount - 10}`);
                        return oldCount - 10;
                    } else {
                        clearInterval(intervalId);
                        console.log('Counter stopped');
                        setLoading(false);
                        setResponseMessage(("TIME_OUT"));
                        return oldCount; // Return current count as it shouldn't decrease further
                    }
                });

                dataGet();

            }, 10000);
        }

        // Cleanup function to clear the interval when component unmounts or dependencies change
        return () => clearInterval(intervalId);
    }, [loading]);


    const dataCtx = useContext(UserDataContext);
    const countInit = 180;
    const [countMonitor, setCountMonitor] = useState(0);

    useEffect(() => {
        let intervalId = setInterval(() => {
            setCountMonitor((oldCount) => {
                if (oldCount > 0) {
                    //console.log(`countMonitor: ${oldCount - 2}`);
                    return oldCount - 1;
                } else {
                    if (dataCtx.isMonitor())
                        dataPost();
                    return countInit; // Return current count as it shouldn't decrease further
                }
            });

        }, 1000);

        // Cleanup function to clear the interval when component unmounts or dependencies change
        return () => clearInterval(intervalId);
    }, [dataCtx.data]);

    async function dataPost() {
        console.log("dataPost();");
        if (loading)
            return;
        setLoading(true)
        setResponseMessage(("REQUEST_SEND"))
        setCount(120);

        try {
            const response = await fetch(config.api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const data = await response.json();
                setResponseMessage(("REQUEST_SENT"));
                console.log("response.ok:", JSON.stringify(data))
                //handleFound(data)
                setProgress(old => old + 1)
            } else {
                setResponseMessage('Error: Something went wrong.');
            }
        } catch (error) {
            setResponseMessage(`Error: ${error.message}`);
            setLoading(false)
        } finally {

        }

    }

    function monitorDec() {
        setCountMonitor(5);
    }

    const [progress, setProgress] = useState(0);

    return (<>
        <div className='card'>
            <h3>Пошук поїздів</h3>
            <hr style={{ "width": "100%" }} />
            <form onSubmit={formSubmit}>
                <span className='container'>
                    {/* <input type='text' name="from" placeholder="From:" value={formData.from} onChange={(e) => handleInputChange(e.target.name, e.target.value)} /> */}
                    <AutoCompleteInput name="from" value={formData.from} onChangeCallback={handleInputChange} />
                    <img src={imgArrows} alt='arrows' onClick={handleSwap} style={{ cursor: 'pointer' }} />
                    <AutoCompleteInput name="to" placeholder="To:" value={formData.to} onChangeCallback={handleInputChange} />
                </span>
                <hr style={{ "width": "100%" }} />

                {/* <input type='date' name="date" placeholder="Date: дд.мм.2024"
                    value={formData.date} onChange={handleInputChange} /> */}

                <CustomDatePicker defaultDate={formatDateCalend(tomorrow())} onChangeCallback={handleInputChange} />

                <button disabled={loading}>&nbsp;Пошук<Loading loading={loading} /> </button>
            </form>
            {dataCtx.isMonitor() && (<span onClick={monitorDec} className='verticalAlign'><img src={imgClock} alt='clock' />{Math.trunc(countMonitor / 60)}:{countMonitor % 60}</span>)}

        </div>

        <Progress max="5" now={progress} />

        <ResponseMsg msg={responseMessage} />



    </>
    )
}