import { useEffect, useState } from 'react';

function WakeLockComponent({ isWakeLockActive }) {
    const [wakeLock, setWakeLock] = useState(null);

    const requestWakeLock = async () => {
        try {
            if (!wakeLock) {
                const wakeLockObj = await navigator.wakeLock.request('screen');
                setWakeLock(wakeLockObj);
                console.log('Wake Lock is active.');
            }
        } catch (err) {
            console.error(`${err.name}, ${err.message}`);
        }
    };

    const releaseWakeLock = async () => {
        if (wakeLock) {
            await wakeLock.release();
            setWakeLock(null);
            console.log('Wake Lock released.');
        }
    };

    useEffect(() => {
        if (isWakeLockActive) {
            requestWakeLock();
        } else {
            releaseWakeLock();
        }

        const handleVisibilityChange = () => {
            if (isWakeLockActive && document.visibilityState === 'visible') {
                requestWakeLock();
            } else {
                releaseWakeLock();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        // Cleanup when the component unmounts
        return () => {
            releaseWakeLock();
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [isWakeLockActive, wakeLock]);

    return (

        <p>{wakeLock ? 'Активоване блокування "сну" (не закривайте програму)' : ''}</p>

    );
}

export default WakeLockComponent;
