import React, { useEffect, useState } from 'react';
import cities from './cities.json';
import { en2Ukr } from "./strings"

const AutoCompleteInput = ({ name, value, onChangeCallback }) => {
    const [inputValue, setInputValue] = useState(value);
    const [suggestions, setSuggestions] = useState([]);

    const handleChange = (event) => {
        const value = event.target.value;
        setInputValue(value);


        // Filter cities based on input
        if (value.length > 0) {
            const filteredSuggestions = cities.filter(city =>
                city.title.toLowerCase().startsWith(en2Ukr(value).toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setInputValue(suggestion.title);
        setSuggestions([]); // Clear suggestions
        onChangeCallback(name, suggestion.title)

    };

    useEffect(() => { setInputValue(value) }, [value])
    return (
        <div style={{ position: 'relative', width: '300px' }}>
            <input
                type="text"
                value={inputValue}
                onChange={handleChange}
                placeholder="Type a city name"
            />
            {suggestions.length > 0 && (
                <ul
                    style={{
                        position: 'absolute',
                        zIndex: 100,
                        top: '100%',
                        left: '0',
                        right: '0',
                        maxHeight: '150px',
                        overflowY: 'auto',
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        marginTop: '2px',
                        padding: '0',
                        listStyle: 'none'
                    }}
                >
                    {suggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            style={{ padding: '8px', cursor: 'pointer', color: 'black', alignItems: 'left' }}
                            onClick={() => handleSuggestionClick(suggestion)}
                        >
                            {suggestion.title}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AutoCompleteInput;
