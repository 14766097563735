import { createContext, useState } from "react";

const UserDataContext = createContext({
    data: [],
    put: () => { },
    get: () => { },
    del: () => { },
    set: () => { },
    rem: () => { },
    clr: () => { },
    isMonitor: () => { },
    monitorData: () => { },
    timerSet: () => { },
    timerGet: () => { }
})

export function UserDataContextProvider({ children }) {
    const [userData, setUserData] = useState([])
    const [timer, setTimer] = useState(0)

    function get() {
        //console.log("get():", userData)
        return userData;
    }

    function put(item_) {
        console.log("put():", item_)
        if (typeof item_ === "string")
            item_ = JSON.parse(item_);
        setUserData((old) => [...old, item_]);
    }

    function del(item_) {
        console.log("del():", item_)
        if (typeof item_ === "string")
            item_ = JSON.parse(item_);
        setUserData(userData.filter((item) => item.timeFrom !== item_.timeFrom))
    }

    function set(item_, onOff) {
        onOff ? put(item_) : del(item_);
    }

    function rem(data_) {
        for (let item_ of data_) {
            setUserData(userData.filter((itemold) => itemold.timeFrom !== item_.timeFrom))
        }
    }

    function clr() {
        setUserData([]);
    }

    function isMonitor() {
        //console.log("isMonitor:" + get().length);
        return get().length > 0;
    }

    //let userData = []
    function monitorData(data_) {
        let itemsDiff = []
        for (let item_ of data_) {
            let itemOld = userData.find(item => item.timeFrom === item_.timeFrom)
            if (itemOld && itemOld.places !== item_.places) {
                itemsDiff.push(item_);
                itemOld.places = item_.places;
            }
        }

        return itemsDiff.length > 0 ? itemsDiff : null;
    }

    function timerSet(data_) {
        setTimer(data_);
    }
    function timerGet() {
        return timer;
    }

    const userDataCtx = {
        data: userData,
        put,
        get,
        del,
        set,
        rem,
        clr,
        isMonitor,
        monitorData,
        timerSet,
        timerGet
    };

    return (
        <UserDataContext.Provider value={userDataCtx}>{children}</UserDataContext.Provider>
    )
}

export default UserDataContext;
